// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	// Common properties for all breakpoints
	%grid-column {
		position: relative;
		width: 100%;
		min-height: 1px; // Prevent columns from collapsing when empty
		padding-right: ($gutter / 2);
		padding-left: ($gutter / 2);
	}

	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		// Allow columns to stretch full width below their breakpoints
		@for $i from 1 through $columns {
			.#{$prefix}-col#{$infix}-#{$i} {
				@extend %grid-column;
			}
		}
		.#{$prefix}-col#{$infix},
		.#{$prefix}-col#{$infix}-auto {
			@extend %grid-column;
		}

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			// Provide basic `.col-{bp}` classes for equal-width flexbox columns
			.#{$prefix}-col#{$infix} {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;
			}
			.#{$prefix}-col#{$infix}-auto {
				flex: 0 0 auto;
				width: auto;
				max-width: none; // Reset earlier grid tiers
			}

			@for $i from 1 through $columns {
				.#{$prefix}-col#{$infix}-#{$i} {
					@include make-col($i, $columns);
				}
			}

			@for $i from 1 through $columns {
				.order#{$infix}-#{$i} {
					order: $i;
				}
			}
		}
	}
}

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container() {
	margin-right: auto;
	margin-left: auto;
	padding-right: ($grid-gutter-width / 2);
	padding-left: ($grid-gutter-width / 2);
	width: 100%;
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

@mixin make-row() {
	display: flex;
	flex-wrap: wrap;
	margin-right: ($grid-gutter-width / -2);
	margin-left: ($grid-gutter-width / -2);
}

@mixin make-col-ready() {
	position: relative;
	// Prevent columns from becoming too narrow when at smaller grid tiers by
	// always setting `width: 100%;`. This works because we use `flex` values
	// later on to override this initial width.
	width: 100%;
	min-height: 1px; // Prevent collapsing
	padding-right: ($grid-gutter-width / 2);
	padding-left: ($grid-gutter-width / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 percentage($size / $columns);
	// Add a `max-width` to ensure content within each column does not blow out
	// the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
	// do not appear to require this.
	max-width: percentage($size / $columns);
}


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
	.#{$prefix}-container {
		@include make-container();
		@include make-container-max-widths();
	}
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
	.#{$prefix}-container-fluid {
		width: 100%;
		@include make-container();
	}
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
	.#{$prefix}-row {
		@include make-row();
	}

	// Remove the negative margin from default .row, then the horizontal padding
	// from all immediate children columns (to prevent runaway style inheritance).
	.no-gutters {
		margin-right: 0;
		margin-left: 0;

		> .#{$prefix}-col,
		> [class*="col-"] {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
	@include make-grid-columns();
}
