.cal-month-view {
	.cal-header {
		text-align: center;
		font-weight: bolder;
	}

	.cal-cell-row:hover {
		background-color: #fafafa;
	}

	.cal-header .cal-cell {
		padding: 5px 0;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		white-space: nowrap;
		font-size: 0.8em;
		font-weight: normal;
	}

	.cal-cell-row .cal-cell:hover,
	.cal-cell.cal-has-events.cal-open {
		background-color: #ededed;
	}

	.cal-days {
		border: 1px solid #e1e1e1;
		border-bottom: 0;
	}

	.cal-cell-top {
		min-height: 78px;
		flex: 1;
	}

	.cal-cell-row {
		display: flex;
	}

	.cal-cell {
		float: left;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.cal-day-cell {
		min-height: 75px;
	}

	.cal-day-cell:not(:last-child) {
		border-right: 1px solid #e1e1e1;
	}

	.cal-days .cal-cell-row {
		border-bottom: 1px solid #e1e1e1;
	}

	.cal-day-badge {
		margin-top: 18px;
		margin-left: 10px;
		background-color: #b94a48;
		display: inline-block;
		min-width: 10px;
		padding: 3px 7px;
		font-size: 12px;
		font-weight: 700;
		line-height: 1;
		color: white;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		border-radius: 10px;
	}

	.cal-day-number {
		font-size: 1em;
		font-weight: 400;
		opacity: 0.5;
		line-height: 100%;
		margin: 10px 5px 5px;
	}

	.cal-events {
		flex: 1;
		align-items: flex-end;
		margin: 3px;
		line-height: 10px;
		display: flex;
		flex-wrap: wrap;
	}

	.cal-event {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		display: inline-block;
		margin: 2px;
		background-color: #1e90ff;
		border-color: #d1e8ff;
		color: #fff;
	}

	.cal-event-title:link {
		color: currentColor;
	}

	.cal-day-cell.cal-in-month.cal-has-events {
		cursor: pointer;
	}

	.cal-day-cell.cal-out-month .cal-day-number {
		opacity: 0.1;
		cursor: default;
	}

	.cal-day-cell.cal-weekend .cal-day-number {
		color: darkred;
	}

	.cal-day-cell.cal-today {
		background-color: #e8fde7;
	}

	.cal-day-cell.cal-today .cal-day-number {
		font-weight: bold;
	}

	.cal-day-cell.cal-drag-over {
		background-color: darken(#ededed, 5%) !important;
	}

	.cal-open-day-events {
		padding: 15px;
		color: white;
		background-color: #555;
		box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
	}

	.cal-open-day-events .cal-event {
		position: relative;
		top: 2px;
	}

	.cal-out-month {
		.cal-day-badge,
		.cal-event {
			opacity: 0.3;
		}
	}

	.cal-day-cell.cal-day-selected,
	.cal-day-cell.cal-day-selected:hover {
		background-color: theme-color("primary");

		.cal-day-number {
			color: #fff;
			opacity: 1;
		}
	}

	.cal-day-in-past {
		opacity: 0.5;
	}

	.cal-day-closed {
		background-color: red;
	}
}
