@font-face {
	font-family: 'up-ibe-icons';
	src: url('../assets/fonts/up-ibe-icons.eot?10246201');
	src: url('../assets/fonts/up-ibe-icons.eot?10246201#iefix') format('embedded-opentype'),
	url('../assets/fonts/up-ibe-icons.woff2?10246201') format('woff2'),
	url('../assets/fonts/up-ibe-icons.woff?10246201') format('woff'),
	url('../assets/fonts/up-ibe-icons.ttf?10246201') format('truetype'),
	url('../assets/fonts/up-ibe-icons.svg?10246201#up-ibe-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'up-ibe-icons';
    src: url('../font/up-ibe-icons.svg?76208347#up-ibe-icons') format('svg');
  }
}
*/

[class^="ibe-icon-"]:before, [class*=" ibe-icon-"]:before {
	font-family: "up-ibe-icons";
	font-style: normal;
	font-weight: normal;
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ibe-icon-bed:before {
	content: '\e801';
}

/* '' */
.ibe-icon-calendar:before {
	content: '\e802';
}

/* '' */
.ibe-icon-card:before {
	content: '\e806';
}

/* '' */
.ibe-icon-chevron-down:before {
	content: '\e807';
}

/* '' */
.ibe-icon-chevron-left:before {
	content: '\e808';
}

/* '' */
.ibe-icon-chevron-right:before {
	content: '\e809';
}

/* '' */
.ibe-icon-chevron-up:before {
	content: '\e80a';
}

/* '' */
.ibe-icon-edit:before {
	content: '\e80b';
}

/* '' */
.ibe-icon-close-circle:before {
	content: '\e80c';
}

/* '' */
.ibe-icon-email:before {
	content: '\e80d';
}

/* '' */
.ibe-icon-lock:before {
	content: '\e80e';
}

/* '' */
.ibe-icon-printer:before {
	content: '\e810';
}

/* '' */
.ibe-icon-shower:before {
	content: '\e811';
}

/* '' */
.ibe-icon-tick-circle:before {
	content: '\e812';
}

/* '' */
.ibe-icon-tick:before {
	content: '\e813';
}

/* '' */
.ibe-icon-tv:before {
	content: '\e814';
}

/* '' */
.ibe-icon-warning-circle:before {
	content: '\e815';
}

/* '' */
.ibe-icon-whatsapp:before {
	content: '\e816';
}

/* '' */
.ibe-icon-wifi:before {
	content: '\e817';
}

/* '' */
.ibe-icon-zoom:before {
	content: '\e818';
}

/* '' */
.ibe-icon-bed-plus:before {
	content: '\e819';
}

/* '' */
.ibe-icon-user:before {
	content: '\e81a';
}

/* '' */
.ibe-icon-plus:before {
	content: '\e81b';
}

/* '' */
.ibe-icon-close:before {
	content: '\e81c';
}

/* '' */
.ibe-icon-cc-visa:before {
	content: '\f1f0';
}

/* '' */
.ibe-icon-cc-mastercard:before {
	content: '\f1f1';
}

/* '' */
.ibe-icon-cc-amex:before {
	content: '\f1f3';
}

/* '' */
.ibe-icon-cc-paypal:before {
	content: '\f1f4';
}

/* '' */
.ibe-icon-cc-stripe:before {
	content: '\f1f5';
}

/* '' */
