@import '_variables';

.#{$prefix}-form-group {
	margin-bottom: 1rem;
}

.#{$prefix}-form-control {
	display: block;
	width: 100%;
	// // Make inputs at least the height of their button counterpart (base line-height + padding + border)
	min-height: 40px;
	padding: .5rem .75rem;
	font-size: $font-size-base;
	line-height: 1.25;
	color: $gray-700;
	background-color: #fff;
	// Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
	background-image: none;
	background-clip: padding-box;
	border: 1px solid rgba(#000, .15);
	border-radius: 0;
	box-shadow: none;
	transform: border-color ease-in-out .15s, box-shadow ease-in-out;

	// Unstyle the caret on `<select>`s in IE10+.
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}

	// Customize the `:focus` state to imitate native WebKit styles.
	&:focus {
		color: $input-focus-color;
		background-color: $input-focus-bg;
		outline: none;
		box-shadow: none;
		border-color: theme-color('primary');
	}

	// Placeholder
	&::placeholder {
		color: $input-placeholder-color;
		// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
		opacity: 1;
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&:disabled,
	&[readonly] {
		background-color: $input-disabled-bg;
		// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
		opacity: 1;
	}

	&.ng-valid {
		color: theme-color("primary");
	}
}

select.form-control {
	&:not([size]):not([multiple]) {
		height: $input-height;
	}

	&:focus::-ms-value {
		// Suppress the nested default white text on blue background highlight given to
		// the selected option text when the (still closed) <select> receives focus
		// in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
		// match the appearance of the native widget.
		// See https://github.com/twbs/bootstrap/issues/19398.
		color: $input-color;
		background-color: $input-bg;
	}
}

.#{$prefix}-custom-select {
	display: inline-block;
	font-size: $font-size-base;
	max-width: 100%;
	min-height: 40px;
	height: $input-height;
	padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
	line-height: $custom-select-line-height;
	color: $custom-select-color;
	vertical-align: middle;
	background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
	background-size: $custom-select-bg-size;
	border: $custom-select-border-width solid $custom-select-border-color;
	@if $enable-rounded {
		border-radius: $custom-select-border-radius;
	} @else {
		border-radius: 0;
	}
	appearance: none;

	&:focus {
		border-color: $custom-select-focus-border-color;
		outline: none;
		@include box-shadow($custom-select-focus-box-shadow);

		&::-ms-value {
			// For visual consistency with other platforms/browsers,
			// supress the default white text on blue background highlight given to
			// the selected option text when the (still closed) <select> receives focus
			// in IE and (under certain conditions) Edge.
			// See https://github.com/twbs/bootstrap/issues/19398.
			color: $input-color;
			background-color: $input-bg;
		}
	}

	&:disabled {
		color: $custom-select-disabled-color;
		background-color: $custom-select-disabled-bg;
	}

	// Hides the default caret in IE11
	&::-ms-expand {
		opacity: 0;
	}

	&.ng-valid {
		color: theme-color("primary");
	}
}

.mat-autocomplete-panel {
	.mat-optgroup {
		.mat-optgroup-label {
			line-height: 35px;
			height: 35px;
			color: theme-color("primary");
		}

		.mat-option:not(.mat-option-multiple) {
			padding-left: 16px;
		}

		.mat-option {
			line-height: 35px;
			height: 35px;
		}
	}
}

.mat-select {
	.mat-select-trigger {
		margin-top: 2px;
	}
}
