@import '_variables';

button {
	font: inherit;

	&:hover {
		cursor: pointer;
	}

	&:focus,
	&:active {
		outline: 0;
	}
}

.#{$prefix}-btn {
	color: #fff;
	display: inline-block;
	font-weight: normal;
	font-family: inherit;
	font-size: 16px;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	padding: 5px 10px;
	transition: background-color 0.3s ease;
	border-radius: 0;

	&:focus,
	&:hover {
		text-decoration: none;
		cursor: pointer;
	}

	&:focus,
	&.focus {
		outline: 0;
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		opacity: .65;
		@include box-shadow(none);
	}

	&:active,
	&.active {
		background-image: none;
	}
}

.#{$prefix}-close-btn {
	background-color: $gray-dark;

	&:focus,
	&:hover {
		background-color: darken($gray-dark, 10%);
	}
}

.#{$prefix}-primary-btn {
	background-color: theme-color("primary");

	&:focus,
	&:hover {
		background-color: darken(theme-color("primary"), 10%);
	}
}

.chckt-button--submit {
	background-color: theme-color("primary");
}

.chckt-button--disabled {
	background-color: #d2dae1;
}
