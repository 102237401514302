// Import Boostrap variables & mixins
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

// Bootstrap variable overrides
$font-family-base: 'Dosis';
$font-size-base: 1em;

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: (
		100: $gray-100,
		200: $gray-200,
		300: $gray-300,
		400: $gray-400,
		500: $gray-500,
		600: $gray-600,
		700: $gray-700,
		800: $gray-800,
		900: $gray-900
) !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #a8b817 !default;

$colors: (
		blue: $blue,
		indigo: $indigo,
		purple: $purple,
		pink: $pink,
		red: $red,
		orange: $orange,
		yellow: $yellow,
		green: $green,
		teal: $teal,
		cyan: $cyan,
		white: $white,
		gray: $gray-600,
		gray-dark: $gray-800
) !default;

$theme-colors: ();
$theme-colors: map-merge((
		primary: #2b8ca1,
		secondary: $gray-600,
		success: $green,
		info: $cyan,
		warning: $yellow,
		danger: $red,
		light: $gray-100,
		dark: $gray-800
), $theme-colors);

$gray-dark: #9b9b9b;
$gray: #f5f5f5;

$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

$input-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;
$border-radius: 0;
$btn-border-radius: 0;
$input-border-radius: 0;
$custom-select-border-color: #fff;
$custom-select-focus-border-color: theme-color("primary");
$custom-select-border-radius: 0;

$base-unit: 8px;
$input-focus-border-color: theme-color("primary");
$form-group-margin-bottom: 1rem !default;
$input-btn-padding-y: .5rem !default;
$input-btn-padding-x: .75rem !default;
$input-btn-line-height: 1.25 !default;
$border-width: 1px !default;
$input-color: $gray-700 !default;
$input-bg: $white !default;
$input-btn-border-width: $border-width !default; // For form controls and buttons
$input-border-color: rgba($black, .15) !default;
$enable-rounded: true !default;
$input-box-shadow: inset 0 1px 1px rgba($black, .075) !default;
$input-focus-color: $input-color !default;
$input-focus-bg: $input-bg !default;

$prefix: 'ibe';
$enable-grid-classes: true;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
		sm: 540px,
		md: 720px,
		lg: 960px,
		xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
