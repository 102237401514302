#toast-container {
	> :hover {
		box-shadow: none;
	}

	div {
		border-radius: 0;
		box-shadow: none;
	}
}

.toast-top-right {
	top: 0;
	right: 0;
}

.toast-success {
	background-color: $green;
}

.toast-error {
	background-color: $red;
}
