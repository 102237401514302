@import '_variables';

.up-ibe {
	font-variant-numeric: lining-nums;
	font-size: 16px;
	margin-bottom: 100px;
}

.up-ibe * {
	line-height: 1.2;
	box-sizing: border-box;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
}

.modal {
	.close {
		outline: none;
	}

	.modal-content {
		border-radius: 0;
		border: none;
	}
}

.carousel {
	outline: none;
}

.chckt-pm:first-child {
	border-radius: 0;
}

/* only show for screen readers */
.sr-only {
	text-indent: -100000px;
	display: inline-block;
}

/* to bring MyCheck iframe overlay in front of the IBE */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
	z-index: 9001;
}

body .adyen-checkout__dropdown__list {
	position: relative;
	z-index: 9001;
}


#mywalletSdk {
	position: relative;
	/* we need the wallet to appear over other website elements, but not IBE elements, like warning boxes */
	z-index: 100;
}
