// Import sass files
// ------------------------------------
@import '_functions';
@import '_mixins';
@import '_variables';
@import '_grid';
@import '_form';
@import '_btn';
@import '_dialog';
@import '_carousel';
@import '_datepicker';
@import '_angular-calendar';

// Import Angular Material css
// ---------------------------
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@adyen/adyen-web/dist/adyen.css';

// Import Angular2 Toaster css
// ---------------------------
@import '../../node_modules/angular2-toaster/toaster';
@import '_toaster-overide';

// Import icon font css
// --------------------
@import '_icons';

// Import our own global styles
// ----------------------------
@import '_global';

.ReactModalPortal {
	position: absolute;
	// Needs to be in front of #mywalletSdk, which has z-index 100
	z-index: 110;
}
