@import '_variables';

.mat-dialog-container {
	max-width: 600px;
	line-height: 1.2;

	p {
		font-size: 0.8em;

		@media (min-width: map-get($grid-breakpoints, md)) {
			font-size: 1em;
		}
	}

	.mat-dialog-header {
		display: flex;
	}

	.mat-dialog-title {
		display: inline-flex;
		color: theme-color("primary");
		text-transform: uppercase;
		line-height: 1.2;
	}

	.mat-dialog-actions {
		padding: 24px 0;

		.ibe-btn {
			margin-left: 5px;
			font-size: 0.8em;

			@media (min-width: map-get($grid-breakpoints, sm)) {
				font-size: 1em;
			}
		}
	}

	@media (min-width: map-get($grid-breakpoints, sm)) {
		min-width: 500px;
	}
}

.full-screen-dialog .mat-dialog-container {
	max-width: none;
	padding: 0;
	border-radius: 0;
	overflow: hidden;

	iframe {
		border: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
